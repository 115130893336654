import logo from './logo.svg';
import './App.css';
import {useState} from 'react';

export default function () {
    const [clicked, setClicked] = useState(false);

    return (
        <>
            {!clicked &&
                <div>
                    <div>
                        Have you ever heard of sawcon?
                    </div>
                    <div>
                        <button onClick={() => setClicked(true)}>No whats that?</button>
                    </div>
                </div>
            }

            {clicked &&
                <div>Saw con deez nuts</div>
            }
        </>
    );
};
