import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBaA5k1qM8sMYumVrAQL4MDQbkjEQR3io4",
  authDomain: "nathan-ward.firebaseapp.com",
  projectId: "nathan-ward",
  storageBucket: "nathan-ward.appspot.com",
  messagingSenderId: "451475048922",
  appId: "1:451475048922:web:17aaa222168e736c2d18e3",
  measurementId: "G-6PNQY556G6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
